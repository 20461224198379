@font-face {
	font-family: 'realestate-icons';
	src:  url('icon-files/realestate.eot?ixg742');
	src:  url('icon-files/realestate.eot?ixg742#iefix') format('embedded-opentype'),
		url('icon-files/realestate.ttf?ixg742') format('truetype'),
		url('icon-files/realestate.woff?ixg742') format('woff'),
		url('icon-files/realestate.svg?ixg742#realestate') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-realestate-"], [class*=" icon-realestate-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'realestate-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-realestate-advert:before {
	content: "\e900";
}
.icon-realestate-air-conditioning:before {
	content: "\e901";
}
.icon-realestate-bag:before {
	content: "\e902";
}
.icon-realestate-balance:before {
	content: "\e903";
}
.icon-realestate-balcony:before {
	content: "\e904";
}
.icon-realestate-barrow:before {
	content: "\e905";
}
.icon-realestate-bathtub:before {
	content: "\e906";
}
.icon-realestate-bed:before {
	content: "\e907";
}
.icon-realestate-billboard:before {
	content: "\e908";
}
.icon-realestate-box:before {
	content: "\e909";
}
.icon-realestate-bricks:before {
	content: "\e90a";
}
.icon-realestate-bridge:before {
	content: "\e90b";
}
.icon-realestate-brush:before {
	content: "\e90c";
}
.icon-realestate-building-crane:before {
	content: "\e90d";
}
.icon-realestate-building:before {
	content: "\e90e";
}
.icon-realestate-building2:before {
	content: "\e90f";
}
.icon-realestate-building3:before {
	content: "\e910";
}
.icon-realestate-bungalow:before {
	content: "\e911";
}
.icon-realestate-buying-a-home:before {
	content: "\e912";
}
.icon-realestate-calculator:before {
	content: "\e913";
}
.icon-realestate-calendar:before {
	content: "\e914";
}
.icon-realestate-chair:before {
	content: "\e915";
}
.icon-realestate-chair2:before {
	content: "\e916";
}
.icon-realestate-chandelier:before {
	content: "\e917";
}
.icon-realestate-check:before {
	content: "\e918";
}
.icon-realestate-china-house:before {
	content: "\e919";
}
.icon-realestate-clip:before {
	content: "\e91a";
}
.icon-realestate-combination-lock:before {
	content: "\e91b";
}
.icon-realestate-compasses:before {
	content: "\e91c";
}
.icon-realestate-concrete-mixer:before {
	content: "\e91d";
}
.icon-realestate-construction-helmet:before {
	content: "\e91e";
}
.icon-realestate-court:before {
	content: "\e91f";
}
.icon-realestate-credit:before {
	content: "\e920";
}
.icon-realestate-dialogue:before {
	content: "\e921";
}
.icon-realestate-doc:before {
	content: "\e922";
}
.icon-realestate-doc2:before {
	content: "\e923";
}
.icon-realestate-doc3:before {
	content: "\e924";
}
.icon-realestate-document:before {
	content: "\e925";
}
.icon-realestate-door:before {
	content: "\e926";
}
.icon-realestate-door2:before {
	content: "\e927";
}
.icon-realestate-drill:before {
	content: "\e928";
}
.icon-realestate-eco:before {
	content: "\e929";
}
.icon-realestate-elevator:before {
	content: "\e92a";
}
.icon-realestate-exchange:before {
	content: "\e92b";
}
.icon-realestate-fence:before {
	content: "\e92c";
}
.icon-realestate-fireplace:before {
	content: "\e92d";
}
.icon-realestate-folder:before {
	content: "\e92e";
}
.icon-realestate-garage:before {
	content: "\e92f";
}
.icon-realestate-garage2:before {
	content: "\e930";
}
.icon-realestate-glasses:before {
	content: "\e931";
}
.icon-realestate-hacksaw:before {
	content: "\e932";
}
.icon-realestate-hammer:before {
	content: "\e933";
}
.icon-realestate-hangar:before {
	content: "\e934";
}
.icon-realestate-heating:before {
	content: "\e935";
}
.icon-realestate-high-voltage:before {
	content: "\e936";
}
.icon-realestate-horn:before {
	content: "\e937";
}
.icon-realestate-hotel:before {
	content: "\e938";
}
.icon-realestate-hotel2:before {
	content: "\e939";
}
.icon-realestate-house-key:before {
	content: "\e93a";
}
.icon-realestate-house:before {
	content: "\e93b";
}
.icon-realestate-house2:before {
	content: "\e93c";
}
.icon-realestate-house3:before {
	content: "\e93d";
}
.icon-realestate-house4:before {
	content: "\e93e";
}
.icon-realestate-house5:before {
	content: "\e93f";
}
.icon-realestate-house6:before {
	content: "\e940";
}
.icon-realestate-house7:before {
	content: "\e941";
}
.icon-realestate-house8:before {
	content: "\e942";
}
.icon-realestate-house9:before {
	content: "\e943";
}
.icon-realestate-imac:before {
	content: "\e944";
}
.icon-realestate-incision-plan:before {
	content: "\e945";
}
.icon-realestate-ipad:before {
	content: "\e946";
}
.icon-realestate-key:before {
	content: "\e947";
}
.icon-realestate-key2:before {
	content: "\e948";
}
.icon-realestate-ladder:before {
	content: "\e949";
}
.icon-realestate-lamp:before {
	content: "\e94a";
}
.icon-realestate-lawn-mower:before {
	content: "\e94b";
}
.icon-realestate-letter:before {
	content: "\e94c";
}
.icon-realestate-light-bulb:before {
	content: "\e94d";
}
.icon-realestate-light-bulb2:before {
	content: "\e94e";
}
.icon-realestate-lock:before {
	content: "\e94f";
}
.icon-realestate-lock2:before {
	content: "\e950";
}
.icon-realestate-love:before {
	content: "\e951";
}
.icon-realestate-mail:before {
	content: "\e952";
}
.icon-realestate-map:before {
	content: "\e953";
}
.icon-realestate-medicine-chest:before {
	content: "\e954";
}
.icon-realestate-mixer:before {
	content: "\e955";
}
.icon-realestate-money:before {
	content: "\e956";
}
.icon-realestate-moneybox:before {
	content: "\e957";
}
.icon-realestate-motorhome:before {
	content: "\e958";
}
.icon-realestate-move:before {
	content: "\e959";
}
.icon-realestate-move2:before {
	content: "\e95a";
}
.icon-realestate-music:before {
	content: "\e95b";
}
.icon-realestate-music2:before {
	content: "\e95c";
}
.icon-realestate-my-house:before {
	content: "\e95d";
}
.icon-realestate-my-key:before {
	content: "\e95e";
}
.icon-realestate-newspapers:before {
	content: "\e95f";
}
.icon-realestate-nightstand:before {
	content: "\e960";
}
.icon-realestate-nippers:before {
	content: "\e961";
}
.icon-realestate-notebook:before {
	content: "\e962";
}
.icon-realestate-pan:before {
	content: "\e963";
}
.icon-realestate-parking:before {
	content: "\e964";
}
.icon-realestate-parquet:before {
	content: "\e965";
}
.icon-realestate-phone:before {
	content: "\e966";
}
.icon-realestate-phone2:before {
	content: "\e967";
}
.icon-realestate-phone3:before {
	content: "\e968";
}
.icon-realestate-pipe-wrench:before {
	content: "\e969";
}
.icon-realestate-plan:before {
	content: "\e96a";
}
.icon-realestate-plan2:before {
	content: "\e96b";
}
.icon-realestate-plan3:before {
	content: "\e96c";
}
.icon-realestate-plant:before {
	content: "\e96d";
}
.icon-realestate-plant2:before {
	content: "\e96e";
}
.icon-realestate-point:before {
	content: "\e96f";
}
.icon-realestate-pointer:before {
	content: "\e970";
}
.icon-realestate-printer:before {
	content: "\e971";
}
.icon-realestate-purse:before {
	content: "\e972";
}
.icon-realestate-purse2:before {
	content: "\e973";
}
.icon-realestate-realtor:before {
	content: "\e974";
}
.icon-realestate-regulator:before {
	content: "\e975";
}
.icon-realestate-rent:before {
	content: "\e976";
}
.icon-realestate-rent2:before {
	content: "\e977";
}
.icon-realestate-restaurant:before {
	content: "\e978";
}
.icon-realestate-roller:before {
	content: "\e979";
}
.icon-realestate-roulette:before {
	content: "\e97a";
}
.icon-realestate-scale:before {
	content: "\e97b";
}
.icon-realestate-search:before {
	content: "\e97c";
}
.icon-realestate-secateurs:before {
	content: "\e97d";
}
.icon-realestate-shop:before {
	content: "\e97e";
}
.icon-realestate-shovel:before {
	content: "\e97f";
}
.icon-realestate-shower:before {
	content: "\e980";
}
.icon-realestate-skyscrapers:before {
	content: "\e981";
}
.icon-realestate-skyscrapers2:before {
	content: "\e982";
}
.icon-realestate-socket:before {
	content: "\e983";
}
.icon-realestate-spatula:before {
	content: "\e984";
}
.icon-realestate-stamp:before {
	content: "\e985";
}
.icon-realestate-statistic:before {
	content: "\e986";
}
.icon-realestate-suitcase2:before {
	content: "\e987";
}
.icon-realestate-swimming-pool:before {
	content: "\e988";
}
.icon-realestate-switch:before {
	content: "\e989";
}
.icon-realestate-tag:before {
	content: "\e98a";
}
.icon-realestate-tap:before {
	content: "\e98b";
}
.icon-realestate-things:before {
	content: "\e98c";
}
.icon-realestate-time:before {
	content: "\e98d";
}
.icon-realestate-tools:before {
	content: "\e98e";
}
.icon-realestate-trowel:before {
	content: "\e98f";
}
.icon-realestate-tv:before {
	content: "\e990";
}
.icon-realestate-vacuum-cleaner:before {
	content: "\e991";
}
.icon-realestate-ventilation:before {
	content: "\e992";
}
.icon-realestate-washing-machine:before {
	content: "\e993";
}
.icon-realestate-window:before {
	content: "\e994";
}
.icon-realestate-wood:before {
	content: "\e995";
}

